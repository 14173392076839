<template>
  <div class="home-four">
    <Header />
    <!-- End Header Section -->

    <Slider />
    <!-- End Slider Section -->

    <div class="shane_tm_section" id="about">
      <div class="shane_tm_about">
        <div class="container">
          <About />
        </div>
        <!--  End container -->
      </div>
    </div>
    <!-- End About Section -->

    <div class="shane_tm_section" id="portfolio">
      <div class="shane_tm_portfolio">
        <Portfolio />
      </div>
    </div>
    <!-- End Portfolio Section -->

    <div class="shane_tm_section">
      <div class="shane_tm_partners">
        <div class="container">
          <div class="partners_inner">
            <Brand />
          </div>
        </div>
      </div>
    </div>
    <!-- End shane_tm_partners -->

    <div id="testimonial" class="shane_tm_section">
      <div class="shane_tm_testimonials">
        <div class="container">
          <div class="testimonials_inner">
            <div class="left" v-scroll-reveal="{ delay: 50 }">
              <div class="shane_tm_title">
                <span>SOM</span>
                <h3>Tú tienes la idea, nosotros nos encargamos del desarrollo.</h3>
              </div>
            </div>

            <div class="right" v-scroll-reveal="{ delay: 150 }">
              <Testimonial />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End  shane_tm_testimonials -->

    

    <CallToActions />
    <!-- End  call to actions -->

    <Footer />
    <!-- End  footer  -->
  </div>
</template>

<script>
import Header from "../components/Header";
import Slider from "../components/slider/SliderFour";
import About from "../components/about/AboutFour";
import Portfolio from "../components/portfolio/PortfolioTwo";
import Brand from "../components/Brand";
import Testimonial from "../components/testimonial/TestimonialTwo";
import CallToActions from "../components/calltoactions/CallToActionsTwo";
import Footer from "../components/Footer";
export default {
  components: {
    Header,
    Slider,
    About,
    Portfolio,
    Brand,
    Testimonial,
    CallToActions,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
