<template>
  <div class="container">
    <div
      class="positon-relative"
      v-scroll-reveal="{ delay: 50, distance: '20px' }"
    >
      <div class="shane_tm_title">
        <div class="title_flex">
          <div class="left">
            <span>Portafolio</span>
            <h3>Nuestros trabajos</h3>
          </div>
        </div>
      </div>
      <!-- End shane_tm_title -->
      <div class="portfolio_filter">
        <ul>
          <li>
            <a
              v-on:click="activetab = 1"
              :class="[activetab === 1 ? 'active' : '']"
              >WEB</a
            >
          </li>
          <!-- End All for tabcontent 1 -->
          <li>
            <a
              v-on:click="activetab = 2"
              :class="[activetab === 2 ? 'active' : '']"
              >Aplicaciones</a
            >
          </li>
          <!-- End Vimeo tabcontent 2 -->
          <li>
            <a
              v-on:click="activetab = 3"
              :class="[activetab === 3 ? 'active' : '']"
              >Juegos</a
            >
          </li>
          <!-- End .Youtube tabcontent 3 -->
          <li>
            <a
              v-on:click="activetab = 4"
              :class="[activetab === 4 ? 'active' : '']"
              >Más</a
            >
          </li>
          <!-- End Mix tabcontent 4 -->
        </ul>
      </div>
      <!-- End .portfolio-filter -->
    </div>

    <div v-if="activetab === 1" class="tabcontent">
      <div class="portfolio_list">
        <ul class="gallery_zoom">
          <CoolLightBox :items="allItems" :index="index" @close="index = null">
          </CoolLightBox>
          <li v-for="(image, imageIndex) in allItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry shane_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
      </div>

      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="vimeoItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in vimeoItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="youtubeItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>
          <li v-for="(image, imageIndex) in youtubeItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 3 -->

    <div v-if="activetab === 4" class="tabcontent">
      <div class="portfolio_list">
        <ul>
          <CoolLightBox
            :items="galleryItems"
            :index="index"
            @close="index = null"
          >
          </CoolLightBox>

          <li v-for="(image, imageIndex) in galleryItems" :key="imageIndex">
            <div class="inner" @click="index = imageIndex">
              <div class="entry tokyo_tm_portfolio_animation_wrap">
                <img class="image" :src="image.thumb" alt="Portfolio" />
              </div>
              <!-- End .entry -->
              <div class="mobile_title">
                <h3>{{ image.portfolioName }}</h3>
                <span>{{ image.portfolioType }}</span>
              </div>
            </div>
          </li>
          <!-- End li -->
        </ul>
        <!-- End .portfolio_list -->
      </div>
    </div>
    <!-- End .tabcontent 4 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      activetab: 1,
      allItems: [
        {
          src: require("../../assets/img/portfolio/buscador.jpg"),
          thumb: require("../../assets/img/portfolio/buscador.jpg"),
          portfolioName: "Buscador Marguz",
          portfolioType: "WEB",
        },
        {
          src: require("../..//assets/img/portfolio/yeyeko.jpg"),
          thumb: require("../../assets/img/portfolio/yeyeko.jpg"),
          portfolioName: "Blog Yeyekó",
          portfolioType: "WEB",
        },
        {
          src: "https://youtu.be/ZOoVOfieAF8",
          thumb: require("../../assets/img/portfolio/estudio.jpg"),
          portfolioName: "Plataforma estudio fotográfico",
          portfolioType: "WEB",
        },
        {
          src: require("../..//assets/img/portfolio/devias.jpg"),
          thumb: require("../..//assets/img/portfolio/devias.jpg"),
          portfolioName: "EPR Devias logistic",
          portfolioType: "WEB",
        },
        {
          src: require("../..//assets/img/portfolio/shop.jpg"),
          thumb: require("../..//assets/img/portfolio/shop.jpg"),
          portfolioName: "Tienda en linea tugustazo",
          portfolioType: "WEB",
        },
        {
          src: require("../..//assets/img/portfolio/facturas.jpg"),
          thumb: require("../..//assets/img/portfolio/facturas.jpg"),
          portfolioName: "Plataforma para el control de facturas",
          portfolioType: "WEB",
        },
        {
          src: require("../..//assets/img/portfolio/ruteo.png"),
          thumb: require("../..//assets/img/portfolio/ruteo.png"),
          portfolioName: "Plataforma de generación de rutas",
          portfolioType: "WEB",
        },
      ],
      //apps
      vimeoItems: [
        {
          src: require("../..//assets/img/portfolio/ssc.jpg"),
          thumb: require("../../assets/img/portfolio/ssc.jpg"),
          portfolioName: "Plataforma de estadísticas SSC",
          portfolioType: "ESCRITORIO",
        },
        {
          src: require("../..//assets/img/portfolio/ar.jpg"),
          thumb: require("../../assets/img/portfolio/ar.jpg"),
          portfolioName: "Realidad aumentada polismarttech",
          portfolioType: "MOVIL",
        },
        {
          src: require("../..//assets/img/portfolio/evidenciapp.jpg"),
          thumb: require("../../assets/img/portfolio/evidenciapp.jpg"),
          portfolioName: "Control de evidencias - paquetería",
          portfolioType: "MOVIL",
        },
      ],
      //juegos
      youtubeItems: [
        {
          src: require("../..//assets/img/portfolio/goup.jpg"),
          thumb: require("../../assets/img/portfolio/goup.jpg"),
          portfolioName: "Videojuego GO UP",
          portfolioType: "MOVIL",
        },
        {
          src: require("../..//assets/img/portfolio/game.jpg"),
          thumb: require("../../assets/img/portfolio/game.jpg"),
          portfolioName: "Videojuego para personas invidentes",
          portfolioType: "MOVIL",
        },
      ],
      //mas
      mixItems: [
        {
          src: require("../../assets/img/portfolio/ssc.jpg"),
          thumb: require("../../assets/img/portfolio/ssc.jpg"),
        },
        {
          src: "https://youtu.be/ZOoVOfieAF8",
          thumb: require("../../assets/img/portfolio/14.jpg"),
        },
      ],
      galleryItems: [
        {
          src: require("../..//assets/img/portfolio/ar2.jpg"),
          thumb: require("../../assets/img/portfolio/ar2.jpg"),
          portfolioName: "Realidad aumentada",
          portfolioType: "MOVIL",
        },
        {
          src: require("../..//assets/img/portfolio/modelado.png"),
          thumb: require("../../assets/img/portfolio/modelado.png"),
          portfolioName: "Modelado 3D",
          portfolioType: "MOVIL",
        },
        
      ],
      index: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
