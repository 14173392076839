<template>
  <div class="about_inner">
    <div class="left logoInicioSom">
      <div class="image">
        <img src="../../assets/img/placeholders/1-1.jpg" alt="placeholder" />
        <div
          class="main "
          v-scroll-reveal="{ delay: 20 }"
          :style="{ backgroundImage: 'url(' + aboutImg + ')' }"
        ></div>
      </div>
      <!-- End image  -->
    </div>
    <!-- End left -->

    <div class="right">
      <div class="shane_tm_title">
        <span v-scroll-reveal="{ delay: 20 }">Acerca de</span>
        <h3 v-scroll-reveal="{ delay: 40 }">
          Desarrollamos software a tu medida.
        </h3>
      </div>
      <div class="text" v-scroll-reveal="{ delay: 60 }">
        <p>
          Somos un equipo apasionado por el desarrollo de software, trabajamos con diversas herramientas tecnológicas y nos 
          adaptamos a las necesidades de tu proyecto para brindarte el mejor servicio, siempre pensando en crear proyectos 
          eficientes, seguros y de calidad. 
        </p>
      </div>
      <div class="shane_tm_button" v-scroll-reveal="{ delay: 80 }">
        <a href="#portfolio">
          Nuestros trabajos
        </a>
      </div>
    </div>
    <!-- End right -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      aboutImg: require("../../assets/img/about/1.jpg"),
      cv: require("../../assets/img/resume/resume.jpg"),
    };
  },
};
</script>
<style scoped>
@media screen and (max-width: 1040px) {
  .logoInicioSom {
    display: none !important;
  }
}
</style>
