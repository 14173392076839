<template>
  <div class="tokyo_tm_contact">
    <div class="fields">
      <form class="contact_form" @submit.prevent="onSubmit">
        <div class="first">
          <ul>
            <li>
              <input
                v-model="formData.name"
                type="text"
                placeholder="Nombre"
                autocomplete="off"
              />
            </li>

            <li>
              <input
                type="text"
                rules="required|email"
                v-model="formData.email"
                placeholder="Correo electrónico"
              />
            </li>

            <li>
              <textarea
                v-model="formData.message"
                placeholder="Tú mensaje "
              ></textarea>
            </li>
          </ul>
        </div>
        <div class="containerErrors">
          <p v-for="(e,key) in errores" :key="key">{{e}}</p>
        </div>
        <div class="containerSuccessMessaje" v-show="successMessage">
          <p>Su mensaje se ha enviado, nos pondremos en contacto contigo lo antes posible</p>
        </div>
        <div class="tokyo_tm_button">
          <button type="submit" class="white-fill-bg fill-black">Enviar</button>
        </div>
      </form>
    </div>
    <!-- END FIELDS -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        email: "",
        message: "",
      },
      errores: [],
      successMessage: false
    };
  },
  methods: {
    onSubmit() {
      this.errores = []
      this.successMessage = false
      this.$sanctum().then(() => {
        let data = {
          name: this.formData.name,
          email: this.formData.email,
          message: this.formData.message,
        }
        this.$http
          .post(this.$api_url + "/api/contact", data)
          .then(() => {
            this.formData.name = ''
            this.formData.email = ''
            this.formData.message = ''
            this.successMessage = true
          })
          .catch((res) => {
            let v = this;
            if (res.response == undefined) {
              v.errores.push("Error inesperado, intenta mas tarde");
              return null;
            }
            if (res.response.status == 422) {
              v.errores = [];
              let keys = Object.keys(res.response.data.errors);
              for (let i = 0; i < keys.length; i++) {
                const element = res.response.data.errors[keys[i]];
                element.forEach((e) => {
                  v.errores.push(e);
                });
              }
              return null;
            }
            if (res.response.status == 429) {
              v.errores.push("Demaciados intentos, espera un minuto");
              return null;
            }

            if (res.response.status == 500) {
              v.errores.push("Error interno del servidor, intenta mas tarde o comunicate a nuestro numero");
              return null;
            }

            v.errores.push(
              `Error: ${res.response.status}, ${res.response.statusText}`
            );
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.containerErrors{
  margin-bottom: 20px;
  p{
    color: rgb(241, 36, 36);
  }
}
.containerSuccessMessaje{
  margin-bottom: 20px;
  p{
    color: rgb(6, 192, 0);
  }
}
</style>