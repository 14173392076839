<template>
  <div class="map_wrap logoContactoDD">
    <div class="logo logoContacto">
      <img src="../assets/img/logo/new/logo.png" alt="som" />
    </div>
  </div>
  <!-- End Google Map -->
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.logoContactoDD{
  margin-top: -50px !important;
}
.logoContacto{
  width: 100% !important;
  text-align: center;
}
.logoContacto img{
  width: 65%;
}
</style>
